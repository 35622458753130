import { format, parseISO } from 'date-fns';

import { ConnectionMode } from '../connect/connection-mode';
import { Pairing } from '../connect/pairing';
import { Sync } from '../connect/sync';
import { Event } from '../timeline/event';
import { Cell } from '../tracking/cell';

import { dayEnd, dayStart } from './date-utils';
import { EventSourceWrapper } from './event-source-wrapper';
import { groupBy } from './group-by';
import { KEY_LAYOUT, KEY_LAYOUT_CHANGED } from './locale.storage.keys';
import { deleteKey, get, getKeys, put, ui } from './storage-impl';

export const SEPERATOR_EVENTS = `\n`;
const SEPERATOR_PARTS = `\t`;

const LS_EVENT_PREFIX = 'events_';

export function eventFromString(s: string): Event {
    const parts = s.split(SEPERATOR_PARTS);
    const e = { s: +parts[0] } as Event;
    switch (parts.length) {
        case 2: // info
            e.i = parts[1];
            break;
        case 3: // activity without end
            e.n = parts[1];
            e.c = parts[2];
            break;
        case 4: // activity with end
            e.n = parts[1];
            e.c = parts[2];
            e.e = +parts[3];
            break;
        default:
            throw new Error('not recognizable as an event: "' + s + '"');
    }
    return e;
}

export function eventToString(e: Event) {
    let s = '' + e.s;
    if (!!e.i) {
        s += SEPERATOR_PARTS + e.i;
    } else {
        s += SEPERATOR_PARTS + e.n + SEPERATOR_PARTS + e.c;
        if (e.e) {
            s += SEPERATOR_PARTS + e.e;
        }
    }
    return s;
}

export function eventsToString(e: Event[]): string {
    return e.map(eventToString).join(SEPERATOR_EVENTS);
}

function compareEvents(a: Event, b: Event) {
    if (a.s > b.s) {
        return 1;
    }
    if (a.s < b.s) {
        return -1;
    }
    if (!!a.i && !b.i) {
        return 1;
    }
    if (!a.i && !!b.i) {
        return -1;
    }
    return (a.n + a.i).localeCompare(b.n + b.i);
}

export function addEventToStrings(s: string, e: Event): Event[] {
    const events = !!s ? s.split(SEPERATOR_EVENTS).map(eventFromString) : [];
    const replace = events.findIndex((o) => o.s === e.s && (o.i !== undefined) === (e.i !== undefined));
    if (replace !== -1) {
        events.splice(replace, 1);
    }
    if (!e.i) {
        const cutoff = events.findIndex((o) => o.s < e.s && (o.e === undefined || o.e > e.s) && o.i === undefined);
        if (cutoff !== -1) {
            if (!e.e) {
                e.e = events[cutoff].e;
            }
            events[cutoff].e = e.s;
        } else if (!e.e) {
            const after = events.findIndex((o) => o.s > e.s && o.i === undefined);
            if (after !== -1) {
                e.e = events[after].s;
            }
        }
    }
    events.push(e);
    events.sort(compareEvents);
    return events.filter((e) => e.i !== ''); // filter out empty infos
}

export function getNextEventDayEnd(from: number): number {
    const available = getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .sort();

    const fromDay = dayStart(from);
    const i = available.findIndex((k) => parseISO(k.substring(LS_EVENT_PREFIX.length)).getTime() > fromDay);
    if (i === -1) {
        return dayEnd(from);
    }
    return dayEnd(parseISO(available[i].substring(LS_EVENT_PREFIX.length)).getTime());
}

export function getEventDayKeys(): string[] {
    return getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .sort();
}

function getDayEvents(key: string) {
    const es = get(key);
    return !!es ? es.split(SEPERATOR_EVENTS).map(eventFromString) : [];
}

export function mergeEvents(es1: string, es2: string) {
    let s = es1;
    const events2 = !!es2 ? es2.split(SEPERATOR_EVENTS).map(eventFromString) : [];
    events2.forEach((e) => {
        s = eventsToString(addEventToStrings(s, e));
    });
    return s;
}

export function getEvents(from: number, to: number) {
    if (from > to) {
        throw new Error('from after to');
    }
    // will return the activity before from, too
    const available = getEventDayKeys();

    const fromDay = dayStart(from);
    let f = Math.max(0, available.findIndex((k) => parseISO(k.substring(LS_EVENT_PREFIX.length)).getTime() >= fromDay) - 1);
    let activityFound = false;
    while (f > 0 && !activityFound) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.filter((e) => !!e.n).length) {
            activityFound = true;
        } else {
            f--;
        }
    }
    const results: Event[] = [];
    while (f < available.length) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.length > 0) {
            results.push(...dayEvents);
            if (dayEvents[dayEvents.length - 1].s > to) {
                break;
            }
        }
        f++;
    }
    let fromActivity = 0;
    let toEvent = -1;
    for (let t = 0; t < results.length; t++) {
        if (!!results[t].n && results[t].s < from) {
            fromActivity = t;
        }
        if (results[t].s <= to) {
            toEvent = t;
        } else {
            break;
        }
    }
    if (toEvent === -1) {
        return [];
    }
    return results.slice(fromActivity, toEvent + 1);
}

export function addEvent(event: Event) {
    const date = format(new Date(event.s), 'yyyy-MM-dd');
    const s = get(LS_EVENT_PREFIX + date);
    const dayEvents = addEventToStrings(s, event);
    put(LS_EVENT_PREFIX + date, dayEvents.map(eventToString).join(SEPERATOR_EVENTS));
}

const DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT = '__#_#QuoTe#_#__';
const COMMA_IN_QUOTES_TEMP_REPLACEMENT = '__#_#CoMma#_#__';

export function validateEventsImport(s: string) {
    const rows = s.split('\n');
    if (rows.length === 0) {
        throw new Error('invalid format');
    }
    if (rows[0] !== 'unixtimestamp,event,color,end') {
        throw new Error('unexpected column format >' + s);
    }
    const p = [0];
    for (let l = 1; l < rows.length; l++) {
        const c = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        if (c.length < 2 || c.length > 4) {
            throw new Error('unexpected number of columns ' + c.length);
        }
        if ('' + Math.floor(Math.abs(+c[0])) !== c[0] || p[0] > +c[0]) {
            throw new Error('invalid unixtimestamp ' + c[0]);
        }
        if (c[2] && !c[2].match(/#([0-9a-f]{3}){1,2}/)) {
            throw new Error('invalid color ' + c[2]);
        }
        if (c[3] && ('' + Math.floor(Math.abs(+c[3])) !== c[3] || +c[0] >= +c[3])) {
            throw new Error('invalid end ' + c[3]);
        }

        p[0] = +c[0];
    }
}

export function clearAllEvents() {
    getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .forEach((k) => deleteKey(k));
}

export function importEvents(s: string, onEvent: (event: Event, userTriggered: boolean) => void) {
    const rows = s.split('\n');
    for (let l = 1; l < rows.length; l++) {
        const line = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .replaceAll(/"(.*?)"/g, '$1')
            .replace(/,+$/g, '');
        const c = line.split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        const e = { s: +c[0] } as Event;
        if (c.length === 2) {
            e.i = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
        } else if (c.length === 3) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
        } else if (c.length === 4) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
            e.e = +c[3];
        } else {
            throw new Error('unexpected amount of columns ' + c.length);
        }
        onEvent(e, true);
    }
}

export const moveCursorToPrevious = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const events = getEvents(time, time);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const previous = times.filter((t) => t < time);
    if (previous.length) {
        const to = previous[previous.length - 1];
        onCursorChanged(to, to === saveNow);
    }
};

export const moveCursorToNext = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const to = getNextEventDayEnd(time);
    const events = getEvents(time, to);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const next = times.filter((t) => t > time);
    if (next.length) {
        const to = next[0];
        onCursorChanged(to, to === saveNow);
    }
};

export type LatestInstances = {
    onEvent: (event: Event, userTriggered: boolean) => void;
    onLayout: (l: string, userTriggered: boolean) => void;
    cursorEvent: Event;
    layout: string;
    refresh: () => void;
    syncCheckRun: number;
};

function getMessagePairing(message: MessageEvent, sync: Sync, k: string, u: string): Pairing | undefined {
    const source = message.lastEventId;
    if (source === (u ?? ui())) {
        return undefined;
    }
    const pairings = Object.keys(sync.pairings)
        .map((pk) => sync.pairings[pk])
        .filter((p) => p.channel === k);
    if (pairings.length !== 1) {
        return undefined;
    }
    return pairings[0];
}

const buffer = {};

export function initEventSources(
    sync: Sync,
    instances: LatestInstances,
    onMessage: (message: string, pairing: Pairing, target: string) => void,
    u: string | undefined = undefined
): EventSourceWrapper[] {
    const channels = groupBy(
        Object.keys(sync.pairings)
            .filter(
                (k) =>
                    [
                        ConnectionMode.STORAGE,
                        ConnectionMode.QRCODE_PUSH,
                        ConnectionMode.SAME_USER,
                        ConnectionMode.SPECTATOR,
                        ConnectionMode.PEEP,
                    ].includes(sync.pairings[k].sync) && sync.pairings[k].active
            )
            .map((k) => sync.pairings[k]),
        (p) => p.channel
    );
    return Object.keys(channels).map(
        (k) =>
            new EventSourceWrapper(`https://time2.event.emphasize.de/?u=${u ?? ui()}&topic=${k}`, {
                event: async (message) => {
                    if (!getMessagePairing(message, sync, k, u)) {
                        return;
                    }
                    instances.onEvent(JSON.parse(message.data) as Event, false);
                },
                spectator: async (message) => {
                    const p = getMessagePairing(message, sync, k, u);
                    if (!p) {
                        return;
                    }
                    onMessage(message.data, p, message.lastEventId);
                },
                peep: async (message) => {
                    const p = getMessagePairing(message, sync, k, u);
                    if (!p) {
                        return;
                    }
                    onMessage(message.data, p, message.lastEventId);
                },
                sync: async (message) => {
                    const p = getMessagePairing(message, sync, k, u);
                    if (!p) {
                        return;
                    }
                    onMessage(message.data, p, message.lastEventId);
                },
                'sync-check': async (message) => {
                    const p = getMessagePairing(message, sync, k, u);
                    if (!p) {
                        return;
                    }
                    const checksums = JSON.parse(message.data);
                    if (
                        (checksums[KEY_LAYOUT] === undefined && !!instances.layout) ||
                        (checksums[KEY_LAYOUT] !== (await getChecksum(instances.layout)) &&
                            checksums[KEY_LAYOUT_CHANGED] <= get(KEY_LAYOUT_CHANGED))
                    ) {
                        await postEvent(p, instances.layout, u);
                    }
                    const toMerge = {};
                    await Promise.all(
                        Object.keys(checksums).map(async (day) => {
                            if (day === KEY_LAYOUT || day === KEY_LAYOUT_CHANGED) {
                                return false;
                            }
                            const content = get(day);
                            if (checksums[day] !== (await getChecksum(content))) {
                                toMerge[day] = content;
                            }
                        })
                    );
                    if (Object.keys(toMerge).length > 0) {
                        try {
                            await postSSE(p, 'sync-merge', JSON.stringify(toMerge), u);
                            // eslint-disable-next-line
                        } catch (error) {}
                    }
                },
                'sync-merge': async (message) => {
                    if (!getMessagePairing(message, sync, k, u)) {
                        return;
                    }
                    const toMerge = JSON.parse(message.data);
                    Object.keys(toMerge).forEach((day) => {
                        let content = get(day);
                        try {
                            const s = toMerge[day];
                            s.split(SEPERATOR_EVENTS).map(eventFromString);
                            content = mergeEvents(content, s);
                            put(day, content);
                        } catch (e) {
                            console.log('sync-merge ' + day + ' failed: ' + e);
                        }
                    });
                    if (instances.refresh) {
                        instances.refresh();
                    }
                    instances.syncCheckRun = 0; // run next iteration
                },
                'sync-override': async (message) => {
                    if (!getMessagePairing(message, sync, k, u)) {
                        return;
                    }
                    if (message.data === '' && buffer['sync-override'] !== undefined) {
                        try {
                            const json = buffer['sync-override'];
                            delete buffer['sync-override'];
                            const toOverride = JSON.parse(json);
                            Object.keys(toOverride).forEach((key) => {
                                if (toOverride[key] === '') {
                                    deleteKey(key);
                                } else if (key.startsWith(LS_EVENT_PREFIX)) {
                                    try {
                                        const s = toOverride[key];
                                        s.split(SEPERATOR_EVENTS).map(eventFromString);
                                        put(key, s);
                                    } catch (e) {
                                        console.log('sync-override skipping corrupted ' + key + ': ' + e);
                                    }
                                } else if (key === KEY_LAYOUT) {
                                    try {
                                        const l = toOverride[key];
                                        Cell.fromJson(l);
                                        put(key, l);
                                    } catch (e) {
                                        console.log('sync-override skipping corrupted ' + key + ': ' + e);
                                    }
                                }
                            });
                            if (instances.refresh) {
                                instances.refresh();
                            }
                        } catch (e) {
                            console.log('sync-override skipping parsing failed: ' + e);
                        }
                    } else {
                        if (!buffer['sync-override']) {
                            buffer['sync-override'] = '';
                        }
                        const str = message.data;
                        buffer['sync-override'] += str.replaceAll('\n', '');
                    }
                    instances.syncCheckRun = 0; // run next iteration
                },
                layout: async (message) => {
                    if (!getMessagePairing(message, sync, k, u)) {
                        return;
                    }
                    instances.onLayout(message.data, false);
                },
                composite: async (message) => {
                    if (!getMessagePairing(message, sync, k, u)) {
                        return;
                    }
                    const data = JSON.parse(message.data);
                    if (Array.isArray(data)) {
                        data.forEach((value) => {
                            if (value['s']) {
                                instances.onEvent(value as Event, false);
                            } else {
                                instances.onLayout(JSON.stringify(value), false);
                            }
                        });
                    }
                },
            })
    );
}

let postBuffer = {};
let bufferCount = 0;
export async function postEvent(pairing: Pairing, e: Event | string, target: string | undefined = undefined) {
    if (bufferCount === 0) {
        setTimeout(async () => {
            if (bufferCount === 0) {
                return;
            }
            const buffer = postBuffer;
            postBuffer = {};
            bufferCount = 0;
            await Promise.all(
                Object.keys(buffer).map(async (key) => {
                    try {
                        await postSSE(buffer[key].pairing, 'composite', '[' + buffer[key].posts.join(',') + ']', buffer[key].target);
                    } catch (error) {
                        console.log('posting event failed ' + JSON.stringify(error) + ', retrying on next event');
                        addPostBuffer(buffer[key].pairing, buffer[key].posts.join(','), buffer[key].target);
                    }
                })
            );
        }, 500);
    }
    bufferCount++;

    addPostBuffer(pairing, e, target);
}

function addPostBuffer(pairing: Pairing, e: Event | string, target: string | undefined = undefined) {
    const key = pairing.channel + '-' + (target ?? '*');
    if (!postBuffer[key]) {
        postBuffer[key] = { pairing, target: target ?? '*', posts: [] };
    }
    postBuffer[key].posts.push(e['s'] ? JSON.stringify(e) : (e as string));
}

export async function postSSE(pairing: Pairing, type: string, data: string, target: string | undefined = undefined) {
    let url = 'https://time2.event.emphasize.de';
    if (pairing.sync === ConnectionMode.STORAGE) {
        url = (pairing.displayName.includes('://') ? '' : 'https://') + pairing.displayName;
    }

    const json = 'event: ' + type + '\ndata: ' + data + '\n';
    return (
        fetch(`${url}/?topic=${pairing.channel}${target !== undefined && target !== '*' ? '&target=' + target : ''}&u=${ui()}`, {
            body: json,
            method: 'POST',
        })
            .then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error('Bad response from server ' + response.status);
                }
                return response;
            })
            // eslint-disable-next-line
            .then((returnedResponse) => {
                // Your response to manipulate
                this.setState({
                    complete: true,
                });
            })
            .catch((error) => {
                throw error;
            })
    );
}

export async function getChecksum(s: string): Promise<string> {
    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(s));
    return Array.from(new Uint8Array(hash))
        .map((b) => ('00' + b.toString(16)).slice(-2))
        .join('');
}
