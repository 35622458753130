import EventEmitter from 'events';

import { createTheme, ThemeProvider, PaletteMode, CssBaseline, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cs from 'date-fns/locale/cs';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import iw from 'date-fns/locale/he';
import hi from 'date-fns/locale/hi';
import hu from 'date-fns/locale/hu';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import no from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import tr from 'date-fns/locale/tr';
import uk from 'date-fns/locale/uk';
import vi from 'date-fns/locale/vi';
import zh from 'date-fns/locale/zh-CN';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Dashboard } from './Dashboard';
import { Reporting } from './reporting/Reporting';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Spectator } from './Spectator';
import { KEY_THEME_MODE } from './utils/locale.storage.keys';
import { useConfig } from './utils/use-config';

const adapterLocales = {
    cs,
    da,
    de,
    el,
    en,
    es,
    fr,
    ga: en, // workaround - irish not available
    hi,
    hu,
    iw,
    ja,
    ko,
    nl,
    no,
    pl,
    ru,
    sv,
    tr,
    uk,
    vi,
    zh,
};
const themeAction = new EventEmitter();

const dashboard = <Dashboard themeAction={themeAction} />;
const router = createBrowserRouter([
    {
        path: ':lang?/',
        element: dashboard,
    },
    {
        path: ':lang?/view/:channel',
        element: <Spectator />,
    },
    {
        path: ':lang?/report',
        element: <Reporting />,
        children: [
            {
                path: ':from/:to',
                element: <Reporting />,
            },
        ],
    },
    {
        path: ':lang?/connections',
        element: dashboard,
    },
    {
        path: ':lang?/export',
        element: dashboard,
    },
    {
        path: ':lang?/import',
        element: dashboard,
    },
    {
        path: ':lang?/legals',
        element: dashboard,
    },
    {
        path: ':lang?/imprint',
        element: dashboard,
    },
    {
        path: '*',
        element: <Navigate to="/" replace={true} />,
    },
]);

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  background: {
                      default: '#ffffff',
                      paper: '#ffffff',
                  },
                  primary: {
                      main: '#000000',
                  },
                  secondary: {
                      main: grey[700],
                  },
                  divider: grey[200],
                  text: {
                      primary: '#000000',
                      secondary: grey[500],
                  },
              }
            : {
                  // palette values for dark mode
                  background: {
                      default: '#000000',
                      paper: '#000000',
                  },
                  primary: {
                      main: '#ffffff',
                  },
                  secondary: {
                      main: grey[200],
                  },
                  divider: grey[700],
                  text: {
                      primary: '#ffffff',
                      secondary: grey[500],
                  },
              }),
    },
});

function App() {
    const { t, i18n } = useTranslation();
    const messageBar = useSnackbar();

    const [adapterLocale] = useState(adapterLocales[i18n.language]);
    const [initThemeMode] = useConfig<PaletteMode>(KEY_THEME_MODE, 'dark');
    const [themeMode, setThemeMode] = useState(initThemeMode);
    const changeTheme = useCallback((newThemeMode) => setThemeMode(newThemeMode), [setThemeMode]);
    useEffect(() => {
        themeAction.on('themeChanged', changeTheme);
        return () => {
            themeAction.off('themeChanged', changeTheme);
        };
    }, [changeTheme]);

    const theme = useMemo(() => {
        return createTheme(getDesignTokens(themeMode));
    }, [themeMode]);

    const onSWUpdate = useCallback(
        (registration: ServiceWorkerRegistration) => {
            messageBar.enqueueSnackbar(t('update.available'), {
                variant: 'success',
                persist: true,
                preventDuplicate: true,
                action: (key) => (
                    <Fragment>
                        <Button
                            size="small"
                            variant="contained"
                            color="success"
                            onClick={() => {
                                if (registration && registration.waiting) {
                                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                }
                                window.location.reload();
                            }}
                        >
                            {t('update.now')}
                        </Button>
                        <Button
                            sx={{ marginLeft: '1rem' }}
                            size="small"
                            variant="contained"
                            color="success"
                            onClick={() => messageBar.closeSnackbar(key)}
                        >
                            {t('update.later')}
                        </Button>
                    </Fragment>
                ),
            });
        },
        [messageBar, t]
    );

    useMemo(() => {
        // check for updates of this app
        serviceWorkerRegistration.register({
            onUpdate: onSWUpdate,
        });
    }, [onSWUpdate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
            <CssBaseline enableColorScheme />
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <App />
        </SnackbarProvider>
    );
}
