export class EventSourceWrapper {
    eventSource: EventSource;

    constructor(
        private url: string,
        private listeners: Record<string, (message: MessageEvent) => Promise<void>>
    ) {
        this.reconnect();
    }

    public close() {
        this.eventSource.close();
    }

    public reconnect() {
        this.eventSource = new EventSource(this.url);
        Object.keys(this.listeners).forEach((t) => this.eventSource.addEventListener(t as string, this.listeners[t], false));
        this.eventSource.onerror = () => {
            if (this.eventSource.readyState !== EventSource.CONNECTING) {
                this.eventSource.close();
                setTimeout(() => this.reconnect(), 5000);
            }
        };
    }
}
