import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConnectionMode } from './connect/connection-mode';
import { Pairing } from './connect/pairing';
import { supportedLngs } from './i18n';
import { Event } from './timeline/event';
import { CellMode } from './tracking/cell-mode';
import { TrackingComponent } from './tracking/Tracking.component';
import { initEventSources, LatestInstances, postSSE } from './utils/event-utils';
import { initialLayout } from './utils/layout-utils';

const spectatorUpdate = [false];
const ui = (+('' + Math.random()).replace(/[,.]/g, '')).toString(36);

const latestInstances = {} as LatestInstances;

export function Spectator() {
    const { t, i18n } = useTranslation();
    const messageBar = useSnackbar();
    const params = useParams();
    const channel = params.channel;
    const lang = params.lang;
    if (lang && lang !== i18n.language && supportedLngs.includes(lang)) {
        i18n.changeLanguage(lang);
    }

    const sync = useMemo(
        () => ({
            pairings: {
                _spectator: {
                    active: true,
                    sync: ConnectionMode.SPECTATOR,
                    channel,
                    pairing: '_spectator',
                    displayName: 'Spectator',
                } as Pairing,
            },
            version: 0,
        }),
        [channel]
    );

    const [cursorEvent, setCursorEvent] = useState<Event>();
    const [layout, setLayout] = useState<string>(initialLayout(t));

    latestInstances.onEvent = useCallback(
        (event: Event) => {
            if (event.n && !event.e) {
                setCursorEvent(event);
                document.title = event.n + ' - Time2Emphasize';
            } else if (event.i) {
                messageBar.enqueueSnackbar(format(event.s, 'HH:mm') + ' ' + event.i, { variant: 'info' });
            } else {
                setCursorEvent(undefined);
                document.title = 'Time2Emphasize';
            }
        },
        [setCursorEvent, messageBar]
    );
    latestInstances.onLayout = useCallback((l: string) => setLayout(l), [setLayout]);

    useEffect(() => {
        // listen to sync events/layouts
        const sses = initEventSources(
            sync,
            latestInstances,
            () => undefined, // spectator won't send updates
            ui
        );
        if (!spectatorUpdate[0]) {
            postSSE(sync.pairings['_spectator'], 'spectator', 'update', '_spectator').then(() => {
                spectatorUpdate[0] = true;
            });
        }
        return () => {
            sses.forEach((sse) => sse.close());
        };
    }, [sync]);

    return (
        <TrackingComponent
            spectatorMode={true}
            mode={CellMode.JUMP}
            cursorEvent={cursorEvent}
            action={undefined}
            messageBar={messageBar}
            onTrack={() => undefined}
            onMode={() => undefined}
            layout={layout}
            onLayout={() => undefined}
        />
    );
}
