import { Event } from '../timeline/event';

import { ConnectionMode } from './connection-mode';
import { Sync } from './sync';

const connectionNamesPickList = [
    'Alpha',
    'Gamma',
    'Theta',
    'Foo',
    'Omega',
    'Solo',
    'Tether',
    'Karma',
    'Bodhi',
    'Maha',
    'Tantra',
    'Sutra',
    'Yogi',
];

function getInitDisplayName(i: number) {
    return (
        connectionNamesPickList[i % connectionNamesPickList.length] +
        (i / connectionNamesPickList.length > 1 ? Math.floor(i / connectionNamesPickList.length) : '')
    );
}

export function correctDisplayName(sync: Sync, pairingKey: string, addSyncMode: ConnectionMode, displayName: string) {
    let initialDisplayNameIndex = 0;
    if (!displayName) {
        let unique = false;
        while (!unique) {
            unique = true;
            Object.keys(sync.pairings).forEach((k) => {
                if (k !== pairingKey) {
                    if (sync.pairings[k].displayName.trim() === getInitDisplayName(initialDisplayNameIndex)) {
                        initialDisplayNameIndex++;
                        unique = false;
                    }
                }
            });
        }
    }
    let correctedDisplayName = displayName ?? getInitDisplayName(initialDisplayNameIndex);
    if ([ConnectionMode.QRCODE_PUSH, ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(addSyncMode)) {
        // correct displayName if not unique
        let unique = false;
        while (!unique) {
            unique = true;
            Object.keys(sync.pairings).forEach((k) => {
                if (k !== pairingKey) {
                    if (sync.pairings[k].displayName.trim() === correctedDisplayName.trim()) {
                        correctedDisplayName =
                            correctedDisplayName.replace(/[0-9]/g, '') + (+correctedDisplayName.replace(/[^0-9]/g, '') + 1);
                        unique = false;
                    }
                }
            });
        }
    }

    return correctedDisplayName;
}

export type LatestType = {
    sync: Sync;
    onEvent: (event: Event, userTriggered: boolean) => void;
    onLayout: (layout: string, userTriggered: boolean) => void;
    onUpdate: (connectionMode: ConnectionMode) => void;
};

export function initLatest(): LatestType {
    return {
        sync: undefined,
        onEvent: undefined, // (event: Event, userTriggered: boolean) => undefined,
        onLayout: undefined, //(layout: string, userTriggered: boolean) => undefined,
        onUpdate: undefined, // (connectionMode: ConnectionMode) => undefined
    };
}

export function updateLatest(
    latest: LatestType,
    sync: Sync,
    onEvent: (event: Event, userEvent: boolean) => void,
    onLayout: (layout: string, userEvent: boolean) => void,
    onUpdate?: (connectionMode: ConnectionMode) => void
) {
    latest.sync = sync;
    latest.onEvent = onEvent;
    latest.onLayout = onLayout;
    latest.onUpdate = onUpdate;
}

export function checkContinue(latest: LatestType, version: number, pairings: string[]): boolean {
    return latest.sync.version === version && !!pairings.map((p) => latest.sync.pairings[p]).filter((p) => p.active).length;
}

export function generatePairingKey(syncMode: ConnectionMode, forcedPairingKey?: string) {
    return syncMode === ConnectionMode.QRCODE_PUSH
        ? '_connect'
        : syncMode === ConnectionMode.SPECTATOR
          ? '_spectator'
          : syncMode === ConnectionMode.PEEP
            ? '_peep'
            : syncMode === ConnectionMode.INVOICE
              ? '_invoice'
              : (forcedPairingKey ?? (+('' + Math.random()).replace(/[,.]/g, '')).toString(36));
}
